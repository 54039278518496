import { logConnectAddress } from '@utils/amplitude';
import React from 'react';

import { useWeb3Context } from '../utils/Web3Context';
import { Button, ButtonColor, ButtonSize } from './Button';

export type ConnectWalletButtonProps = { size?: ButtonSize; color?: ButtonColor };
export const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
  size = 'lg',
  color = 'gradient-v2',
}) => {
  const { onConnectWallet } = useWeb3Context();

  return (
    <Button
      color={color}
      size={size}
      rounded
      block
      onClick={() => {
        logConnectAddress();
        onConnectWallet();
      }}
    >
      <span>Connect Wallet</span>
    </Button>
  );
};
