import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

export type IconButtonProps = {
  rounded?: boolean;
  roundedLeft?: boolean;
  roundedRight?: boolean;
  isActive?: boolean;
  color: 'blue' | 'yellow' | 'primary' | 'secondary';
  fullWidth?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton: React.FC<IconButtonProps> = props => {
  const {
    disabled,
    rounded,
    roundedLeft,
    roundedRight,
    isActive,
    color,
    fullWidth,
    children,
    ...otherProps
  } = props;

  return (
    <button
      type="button"
      disabled={disabled}
      className={classNames(
        'flex justify-center items-center h-14 relative before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 cursor-pointer before:opacity-30 dark:before:opacity-10',
        {
          'rounded-xl before:rounded-xl': rounded,
          'rounded-l-xl before:rounded-l-xl': roundedLeft,
          'rounded-r-xl before:rounded-r-xl': roundedRight,
          'w-14': !fullWidth,
          'w-full': fullWidth,

          'cursor-default': disabled,
          'hover:before:opacity-20 dark:hover:before:opacity-20': !disabled,
          'text-gray-400  before:bg-gray-400 dark:text-white  dark:before:bg-white': !isActive,
          'text-neon-blue-700 before:bg-neon-blue-700 dark:text-neon-blue-500 dark:before:bg-neon-blue-500':
            isActive && color === 'blue',
          'text-yellow-400 before:bg-yellow-100 dark:text-neon-yellow-500 dark:before:bg-neon-yellow-500':
            isActive && color === 'yellow',
          'text-primary-light-mode-main before:bg-primary-light-mode-light dark:text-primary-dark-mode-main dark:before:bg-primary-dark-mode-main':
            isActive && color === 'primary',
          'text-secondary-light-mode-main before:bg-secondary-light-mode-main dark:text-secondary-dark-mode-main dark:before:bg-secondary-dark-mode-main':
            isActive && color === 'secondary',
        }
      )}
      {...otherProps}
    >
      {children}
    </button>
  );
};
