import React, { useEffect, useState } from 'react';

import { useCustomTheme } from '@utils/theme';
import { IconButton } from './IconButton';
import MoonIcon from '@assets/icons/moon.svg';
import SunIcon from '@assets/icons/sun.svg';

export interface ThemeChangerProps {}

export const ThemeChanger: React.FC<ThemeChangerProps> = props => {
  const { theme, isDark, toggleTheme } = useCustomTheme();
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);

  useEffect(() => {
    if (!isThemeLoaded && theme) {
      setIsThemeLoaded(true);
    }
  }, [isThemeLoaded, theme]);

  return isThemeLoaded ? (
    <div className="flex">
      <IconButton
        color="primary"
        roundedLeft
        isActive={!isDark}
        disabled={!isDark}
        onClick={toggleTheme}
      >
        <SunIcon className="h-6" />
      </IconButton>
      <IconButton
        color="yellow"
        roundedRight
        isActive={isDark}
        disabled={isDark}
        onClick={toggleTheme}
      >
        <MoonIcon className="h-7" />
      </IconButton>
    </div>
  ) : null;
};
