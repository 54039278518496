import { Select, SelectOption } from '@components/Select';
import React, { useEffect, useState } from 'react';
import { shortAddress } from '../utils/address';
import { useWeb3Context } from '../utils/Web3Context';
import { ConnectWalletButton } from './ConnectWalletButton';
import { ButtonSize } from '@components/Button';

export type MetaMaskConnectProps = { buttonSize?: ButtonSize };

export const MetaMaskConnect: React.FC<MetaMaskConnectProps> = ({ buttonSize }) => {
  const { address } = useWeb3Context();
  const [selectedWallet, setSelectedWallet] = useState<SelectOption<string> | undefined>(undefined);

  // TODO adopt to multiple addresses when it will be implemented
  useEffect(() => {
    if (!selectedWallet && address) {
      const selectOption = { name: shortAddress(address), value: shortAddress(address) };
      setSelectedWallet(selectOption);
    }
  }, [address, selectedWallet]);

  return (
    <div>
      {address ? (
        <div className="relative flex items-center font-grifter w-full pr-10 text-left pt-4 pb-3 pl-5 border border-neon-blue-700 dark:border-neon-blue-500 border-opacity-20 rounded-xl bg-gray-100 dark:bg-gray-850">
          {/* <div className="border border-neon-blue-700 dark:border-neon-blue-500 border-opacity-20 rounded-xl bg-gray-100 dark:bg-gray-850">
           <Select<string>
             list={[selectedWallet]}
             onChange={wallet => {
               setSelectedWallet(wallet);
             }}
             value={selectedWallet}
             hideBorder
           />
          </div> */}
          {shortAddress(address)}
        </div>
      ) : (
        <ConnectWalletButton size={buttonSize} />
      )}
    </div>
  );
};
