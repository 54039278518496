import useSWR from 'swr';
import qsStringify from 'qs-stringify';
import { EventsApi } from '@api/collection/events/[contractAddress]';
import { BASE_URL } from './config';
import { useDebouncedValue } from '@utils/hooks/useDebouncedValue';
import { SearchApi } from 'pages/api/search/[term]';
import { WalletSearchApi } from '@pages/api/wallet/search/[term]';

export function useCollectionEvents(options: {
  contractAddress?: string | null;
  days: number;
  removeOutliers: boolean;
  includeTokens: boolean;
  includeTokenTraits?: boolean;
  fallbackData?: EventsApi;
  isListings?: boolean;
}) {
  const { contractAddress, days, removeOutliers, includeTokens, fallbackData, isListings } =
    options;

  const { data, isValidating } = useSWR<EventsApi>(
    contractAddress
      ? `${BASE_URL}/api/collection/events/${contractAddress}?${qsStringify({
          days,
          removeOutliers: removeOutliers ? 'true' : 'false',
          includeTokens: includeTokens ? 'true' : 'false',
          includeTokenTraits: includeTokens ? 'true' : 'false',
          isListings: isListings ? 'true' : 'false',
        })}`
      : null,
    {
      fallbackData,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return { data, isValidating };
}

export function useSearch(term: string) {
  const debouncedSearchValue = useDebouncedValue(term, 200);

  const { data, isValidating } = useSWR<SearchApi>(
    debouncedSearchValue ? `${BASE_URL}/api/search/${debouncedSearchValue}` : null
  );

  return { data, isValidating };
}

export function useCollectionSearch(term: string) {
  const debouncedSearchValue = useDebouncedValue(term, 200);

  const { data, isValidating } = useSWR<SearchApi>(
    debouncedSearchValue
      ? `${BASE_URL}/api/search/${debouncedSearchValue}?collectionsOnly=true`
      : null
  );

  return { data, isValidating };
}

export function useWalletSearch(term: string) {
  const debouncedSearchValue = useDebouncedValue(term, 200);

  const { data, isValidating } = useSWR<WalletSearchApi>(
    debouncedSearchValue ? `${BASE_URL}/api/wallet/search/${debouncedSearchValue}` : null
  );

  return { data, isValidating };
}

export function paginate(options: { skip: number; take: number }) {
  return qsStringify(options);
}
