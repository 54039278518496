import { ethers, BigNumberish } from 'ethers';
import trimEnd from 'lodash/trimEnd';
import { Trade } from '@components/TradingActivity';
import { isBefore, subDays } from 'date-fns';

export function calculateFloorPriceChange(event: Trade) {
  const collectionStats = event.token?.collection?.collectionStats?.[0];
  const previousStats = event.token?.collection?.collectionStats?.[1];
  const price = parseFloat(
    (event.buyerPaidEth || event.totalPrice || event.startingPrice || 0).toFixed(4)
  );
  const eventTriggeredFloorChange =
    event?.date && collectionStats?.updatedAt && event?.date < collectionStats?.updatedAt;
  let currentFloor = parseFloat((collectionStats?.floorPrice || 0).toFixed(4));
  // sometimes the floor price was updated by this specific event, if that's the case we want to use the previous floor price so we can demonstrate the change
  if (
    previousStats &&
    currentFloor === price &&
    price !== previousStats?.floorPrice &&
    eventTriggeredFloorChange
  ) {
    currentFloor = parseFloat((previousStats?.floorPrice || 0).toFixed(4));
  }

  const changeFromFloor = priceChange({ previous: currentFloor, current: price }) as number;
  const percentFromFloor = changeFromFloor
    ? formatPercentChange(changeFromFloor, true, true)
    : null;
  // make sure our floor price was updated recently
  const isFloorPriceRecentlyUpdated = collectionStats?.updatedAt
    ? isBefore(subDays(new Date(), 1), new Date(collectionStats?.updatedAt))
    : false;

  const showChangeFromFloor =
    event.type === 'created' && percentFromFloor && isFloorPriceRecentlyUpdated;
  return {
    changeFromFloor: showChangeFromFloor ? changeFromFloor : null,
    changeFromFloorString: showChangeFromFloor
      ? `${percentFromFloor} ${changeFromFloor > 0 ? 'above' : 'below'} floor`
      : null,
  } as const;
}

export function priceChange(options: {
  previous?: number | null;
  current?: number | null;
}): number | undefined {
  const { previous, current } = options;

  if (!previous || !current) return;

  return ((current - previous) / previous) * 100;
}

export function formatPercentChange(
  change: number,
  showAsAsoluteNumber = false,
  round = false
): string {
  const shouldRound = round && (change >= 1 || change <= -1);
  const roundedChange = shouldRound ? Math.round(change) : change;
  const percent = (showAsAsoluteNumber ? Math.abs(roundedChange) : roundedChange).toFixed(
    shouldRound ? 0 : 1
  );
  const percentString = `${percent}%`;

  if (change > 0 && !showAsAsoluteNumber) return `+${percentString}`;
  return percentString;
}

function formatPriceWithSignificantFigures(number: number, maxDecimals = 4) {
  if (number === 0) return 0;
  else if (number >= 1 && number % 1 === 0) return number;
  else if (number > 1 && number % 1 !== 0) return formatPrice(number, 2);
  else if (number >= 0.1) return formatPrice(number, 3);
  else if (number < 0.1) return formatPrice(number, maxDecimals);
  else return number;
}
export const ETH_SYMBOL = 'Ξ';

export const formatEthPrice = (options: { price?: number | string; isGwei?: boolean }): string => {
  const { price, isGwei } = options;
  if (typeof price === 'undefined' || price === null) return '';
  const isString = typeof price === 'string';
  const parsedPrice = isGwei
    ? parseFloat(ethers.utils.formatEther(isString ? price : price.toString()))
    : isString
    ? parseFloat(price)
    : price;
  if (isNaN(parsedPrice)) return '';
  return `${formatPriceWithSignificantFigures(parsedPrice)} ${ETH_SYMBOL}`;
};

export function formatPrice(ethPrice: number, maxDecimals = 3) {
  return trimEnd(trimEnd(ethPrice.toFixed(maxDecimals), '0'), '.');
}

export function weiToEth(value?: BigNumberish) {
  if (typeof value === 'undefined' || value === null) return;
  return parseFloat(ethers.utils.formatEther(value));
}
